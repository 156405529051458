(function(){
    $.fn.headerNav = function(){
        var _this = this,
            $this = $(_this);

        this.init = function() {
            $this.addClass('desktop-nav-menu');

            $(document).on('click', '.header-nav-menu-toggle-js', function(e){
                e.preventDefault();

                $(this).toggleClass('opened');
                $this.toggleClass('active');
                $this.toggleClass('opened');
                $(".language-block").toggleClass('language-block--hidden');
                $this.closest('.header-navbar').toggleClass('navbar-bg');
                $('body').toggleClass('desktop-nav-opened');
            })
        };

        this.init();
    };
    if ($(window).width() > 1200) {
        $('#mmenu').headerNav();
    }
    //$('#mmenu').headerNav();
})();
