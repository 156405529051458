(function(){
    Muller.fullViewport = function() {
        var wHeight = $(window).height(),
            $subMenu = $('.sub-menu'),
            subMenuHeight = $subMenu.height(),
            newHeight = (wHeight - subMenuHeight);

        //$('.full-vp, .full-vp-always').height($(window).height());
        if($(window).width() >= 1200) {
            $('.full-vp, .full-vp-always').height(newHeight);
        } else {
            $('.full-vp, .full-vp-always').height($(window).height());
        }

    };
    Muller.fullViewport();
})();
