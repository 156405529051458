(function(){
    $(".fittext").fitText(
        1.2,
        {
            minFontSize: '30px',
            maxFontSize: '250px'
        }
    );

    $(".fittext-short-h1").fitText(
        1.2,
        {
            minFontSize: '45px',
            maxFontSize: '250px'
        }
    );

    $(".fittext-h2").fitText(
        1.2,
        {
            minFontSize: '20px',
            maxFontSize: '40px'
        }
    );

})();
