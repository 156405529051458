(function(){
    window.Muller = {};

    Muller.isMobileDevice = function(){
        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
           return true;
        }
        return false;
    };

    Muller.removeClass = function(el, className) {
        if (el.classList)
            el.classList.remove(className);
        else
            el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    };

    if (Muller.isMobileDevice()) { $('body').addClass('is-mobile-device'); }
        else { $('body').removeClass('is-mobile-device'); }

})();
