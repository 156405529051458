(function(){
    $('.header-carousel').slick({
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4700,
        speed: 1300,
        arrows: false,
        draggable: false,
        swipe: false,
        touchMove: false,
        cssEase:'ease-out',
        fade: true
    });
})();
