(function(){
    window.sr = ScrollReveal();

    srConf = {
        easing: 'ease-out',
        origin: 'bottom',
        scale: 0,
        opacity: 0.4,
        duration: 500,
        mobile: false,
        distance: '50px'
    };
    if (!Muller.isMobileDevice()) {
        sr.reveal('.sr-reveal', srConf);
        sr.reveal('.sr-reveal-left', {origin: 'left'});
        sr.reveal('.sr-reveal-right', {origin: 'right'});
        sr.reveal('.sr-reveal-top', {origin: 'top', duration: 300});
        sr.reveal('.sr-reveal-box', {}, 50);
    }

})();
