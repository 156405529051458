(function () {

    Muller.initMMenu = function() {
        var $mmenu = $('#mmenu');
        $('body').addClass('mm-init');
        $mmenu.removeClass('desktop-nav-menu');
        $('.header-nav-menu-toggle-js.opened').removeClass('opened');
        $mmenu.mmenu({
            navbar: {
                add: true
            },
            offCanvas: {
                position: "right"
            }
        }, {
            classNames: {
                fixedElements: {
                    fixed: "navbar-fixed"
                }
            },
            offCanvas: {
                pageSelector: ".page-wrapper"
            }
        });

        $mmenu.data('mmenu').bind('opened', function () {
            var $btn = $('.header-nav-menu-toggle-js');
            $btn.addClass('opened');
        });

        $mmenu.data('mmenu').bind('closed', function () {
            var $btn = $('.header-nav-menu-toggle-js');
            $btn.removeClass('opened');
        });

        $(".mm-listview >li >.uppercase").each(function(i){
            if (i >= 5){
                return
            }
            var menuentry = $(".mm-listview >li >.uppercase").eq(i);
            menuentry.attr({
                "href":"#mm-"+ (i+2),
                "data-target": "#mm-"+ (i+2)
            });
        });

        $(".mm-divider").removeClass("mm-divider");

    };

    if ($(window).width() < 1200) {
        Muller.initMMenu();
    }
})();
