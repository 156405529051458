$(window).load(function() {

	var sub = $('#sub-menu'),
		header = $('.site-header'),
		subLink = $('#sub-menu a'),
		topic = $('.topics')

	if (sub.length && $(window).width() > 1024) {
        $(window).scroll(function() {

            if (header.height() - ($('.navbar').height()+50) < $(window).scrollTop()) {
                sub.addClass('scrolled');
            } else if ($(window).scrollTop() < header.height() - ($('.navbar').height()+50)) {
                sub.removeClass('scrolled');
            }
        });
        
        // Dummycode to make submenu items active
        //sub.find('a[href*='+ window.location.pathname.replace(/public/, '').replace(/\//g, '').replace(/.php/, '') +']').parent('li').addClass('active');
    }
});
