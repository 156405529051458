(function(){
    var offset = 30;
    $(window).on('scroll', function(){
        if ($(window).scrollTop() > offset) {
            if (!$('body').hasClass('desktop-nav-opened') && !$('body').hasClass('vp-scrolled') && !$('body').hasClass('mm-init')){
                $('body').addClass('desktop-nav-opened');
                $('header div').addClass('navbar-bg');
                $('header div a').addClass('opened');
                $('#mmenu').addClass('opened');
            }
            $('body').addClass('vp-scrolled');
        } else {
            $('body').removeClass('vp-scrolled');
            if ($('body').hasClass('desktop-nav-opened')) {
                $('body').removeClass('desktop-nav-opened');
                $('header div').removeClass('navbar-bg');
                $('#mmenu').removeClass('opened');
                $('header div a').removeClass('opened');
            }
        }
    });
})();
